/***    General     ***/
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif
}

body {
    margin: 0 auto
}

.m-5-auto {
    margin: 0rem auto
}

.primary-button {
    margin-top: 5rem;
    margin-right: 1rem;
    padding: .6rem;
    width: 10rem;
    background: #222;
    border: none;
    color: #fff;
    font-size: 1.2rem;
    transition: all .5s;
    cursor: pointer;
    text-transform: capitalize
}

/***    Landing Page     ***/
.main-title,
.main-para {
    color: #f1f1f1
}

.main-title {
    padding-top: 0rem;
    font-size: 5rem;
    font-family: 'Fascinate', cursive;
    text-transform: uppercase
}

.main-para {
    font-size: 2.5rem;
    text-Transform: capitalize
}

#reg_btn span {
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

#reg_btn span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

#reg_btn:hover span {
    padding-right: 25px;
}

#reg_btn:hover span:after {
    opacity: 1;
    right: 0;
}

/***    Login Page     ***/
h2 {
    font-weight: 300
}

form {
    display: inline-block;
    background: #f3f3f3;
    border: 1px solid #ddd;
    border-radius: 2px;
    padding: 2rem;
    margin: 2rem 0 1rem 0
}

form label {
    float: left;
    font-size: .9rem;
    margin: 0;
    padding: 0
}

.right-label {
    float: right;
    cursor: pointer
}

input {
    width: 15rem;
    padding: .3rem;
    border-radius: 5px;
    outline: none;
    border: none
}

#sub_btn {
    display: block;
    width: 100%;
    padding: .3rem;
    border: none;
    background: #222;
    color: #fff;
    border-radius: 3px;
}

#sub_btn:hover {
    background: #333;
    transition: all .5s
}

footer p {
    margin: 0;
    font-size: .9rem
}

/***    Register Page     ***/
#checkbox {
    width: 1rem
}

form span {
    font-size: .8rem
}

#reset_pass_lbl {
    float: left
}

/***    Home Page     ***/
.home-page-title {
    color: #222
}

.joinChatContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.joinChatContainer h3 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.joinChatContainer input {
    width: 210px;
    height: 40px;
    margin: 7px;
    border: 2px solid #43a047;
    border-radius: 5px;
    padding: 5px;
    font-size: 16px;
}

.joinChatContainer button {
    width: 225px;
    height: 50px;
    margin: 7px;
    border: none;
    border-radius: 5px;
    padding: 5px;
    font-size: 16px;
    background: #43a047;
    color: #fff;
    cursor: pointer;
}

.joinChatContainer button:hover {
    background: #2e7d32;
}

.chat-window {
    width: '100%';
    height: '80%';
    /* margin-left: 230px; */
}

.chat-window p {
    margin: 0;
}

.chat-window .chat-header {
    height: 45px;
    border-radius: 6px;
    background: #263238;
    position: relative;
    cursor: pointer;
}

.chat-window .chat-header p {
    display: block;
    padding: 0 1em 0 2em;
    color: #fff;
    font-weight: 700;
    line-height: 45px;
}

.chat-window .chat-body {
    /* height: calc(450px - (45px + 70px)); */
    height: 50vh;
    border: 1px solid #263238;
    background: #fff;

    position: relative;
}

.chat-window .chat-body .message-container {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.chat-window .chat-body .message-container::-webkit-scrollbar {
    display: none;
}

.chat-window .chat-body .message {
    height: auto;
    padding: 10px;
    display: flex;
}

.chat-window .chat-body .message .message-content {
    width: '100%';
    height: auto;
    min-height: 40px;
    max-width: '100%';
    background-color: #43a047;
    border-radius: 5px;
    color: white;
    display: flex;
    align-items: center;
    margin-right: 5px;
    margin-left: 5px;
    padding-right: 5px;
    padding-left: 5px;
    overflow-wrap: break-word;
    word-break: break-word;
}

#you {
    justify-content: flex-start;
}

#you .message-content {
    justify-content: flex-start;
}

#you .message-meta {
    justify-content: flex-start;
    margin-left: 5px;
}

#other {
    justify-content: flex-end;
}

#other .message-content {
    justify-content: flex-end;
    background-color: cornflowerblue;
}

#other .message-meta {
    justify-content: flex-end;
    margin-right: 5px;
}

.message-meta #author {
    margin-left: 10px;
    font-weight: bold;
}

.chat-window .chat-body .message .message-meta {
    display: flex;
    font-size: 12px;
}

.chat-window .chat-footer {
    height: 60px;
    border: 1px solid #263238;
    border-top: none;
    display: flex;
    margin-bottom: '2%'
}

.chat-window .chat-footer input {
    height: 90%;
    flex: 80%;
    border: 0;
    padding: 0 0.7em;
    font-size: 1em;
    border-right: 1px dotted #607d8b;

    outline: none;
    font-family: "Open Sans", sans-serif;
}

.chat-window .chat-footer button {
    border: 0;
    display: grid;
    place-items: center;
    cursor: pointer;
    flex: 20%;
    height: 100%;
    padding: 0.0em 1.9em 0.6em 1.4em;
    background: transparent;
    outline: none;
    font-size: 25px;
    color: lightgray;
    margin-bottom: '13px'
}

.chat-window .chat-footer button:hover {
    color: #43a047;
}

.hide {
    opacity: 0 !important;
}

:root {
    --light-grey: #F6F9FC;
    --dark-terminal-color: #0A2540;
    --accent-color: #635BFF;
    --radius: 3px;
}

body {
    padding: 20px;
    font-family: 'Raleway';
    /* display: flex; */
    justify-content: center;
    font-size: 1.2em;
    color: var(--dark-terminal-color);
}

main {
    width: 480px;
}

form>* {
    margin: 10px 0;
}

button {
    background-color: var(--accent-color);
}

button {
    background: var(--accent-color);
    border-radius: var(--radius);
    color: white;
    border: 0;
    padding: 12px 16px;
    margin-top: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    display: block;
}

button:hover {
    filter: contrast(115%);
}

button:active {
    transform: translateY(0px) scale(0.98);
    filter: brightness(0.9);
}

button:disabled {
    opacity: 0.5;
    cursor: none;
}

input,
select {
    display: block;
    font-size: 1.1em;
    width: 100%;
    margin-bottom: 10px;
}

label {
    display: block;
}

a {
    color: var(--accent-color);
    font-weight: 900;
}

small {
    font-size: .6em;
}

fieldset,
input,
select {
    border: 1px solid #efefef;
}

#payment-form {
    border: #F6F9FC solid 1px;
    border-radius: var(--radius);
    padding: 20px;
    margin: 20px 0;
    box-shadow: 0 30px 50px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%);
}

#messages {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New';
    display: none;
    /* hide initially, then show once the first message arrives */
    background-color: #0A253C;
    color: #00D924;
    padding: 20px;
    margin: 20px 0;
    border-radius: var(--radius);
    font-size: 0.7em;
}